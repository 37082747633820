@use "sass:math";

html {
  height: 100dvh;
  font-size: 10px;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100dvh;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5em;
  background: $page-bg;
  padding-bottom: $breithorn-min-scale-factor *
    $breithorn-unscaled-usable-height;
  color: white;

  @media screen and (min-width: $breithorn-min-scaled-width) {
    padding-bottom: percentage(
      math.div($breithorn-unscaled-usable-height, $breithorn-unscaled-width)
    );
  }

  > .page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-grow: 1;

    main {
      padding: 30px;
    }
  }

  > .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    left: 0;
    bottom: 0;
    background-color: $page-bg;
    background-image: url("../images/breithorn-bg.jpg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: $breithorn-min-scaled-width auto;

    @media screen and (min-width: $breithorn-min-scaled-width) {
      background-size: 100% auto;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      bottom: $breithorn-min-scale-factor * $breithorn-unscaled-usable-height;
      @include gradient-y(
        $start-color: rgba($page-bg-dark, 100%),
        $end-color: rgba($page-bg-dark, 0)
      );

      @media screen and (min-width: $breithorn-min-scaled-width) {
        bottom: percentage(
          math.div($breithorn-unscaled-usable-height, $breithorn-unscaled-width)
        );
      }
    }
  }

  ::selection {
    background: white;
    color: black;
    text-shadow: none;
  }
  img::selection {
    background: 0 0;
  }
}

a {
  color: $text-alt-color;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    color: adjust-color($text-alt-color, $lightness: +10%);
  }
}
