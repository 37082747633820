main {
  text-align: center;
  margin: 50px 30px;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .actions {
    margin-top: 30px;
  }
}
