@use "sass:math";

$font-family-sans-serif: "Open Sans", "Segoe UI", "Arial", sans-serif;

$page-bg: #2e5f9c;
$page-bg-dark: adjust-color($page-bg, $lightness: -20%);

$breithorn-unscaled-width: 1920px;
$breithorn-min-scaled-width: 1000px;
$breithorn-unscaled-usable-height: 300px;
$breithorn-min-scale-factor: math.div(
  $breithorn-min-scaled-width,
  $breithorn-unscaled-width
);

$text-alt-color: adjust-color($page-bg, $lightness: +40%);
