@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-light-webfont.woff2") format("woff2"),
    url("../fonts/opensans-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-lightitalic-webfont.woff2") format("woff2"),
    url("../fonts/opensans-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-regular-webfont.woff2") format("woff2"),
    url("../fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-regularitalic-webfont.woff2") format("woff2"),
    url("../fonts/opensans-regularitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-extrabold-webfont.woff2") format("woff2"),
    url("../fonts/opensans-extrabold-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-extrabolditalic-webfont.woff2") format("woff2"),
    url("../fonts/opensans-extrabolditalic-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-semibold-webfont.woff2") format("woff2"),
    url("../fonts/opensans-semibold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans-semibolditalic-webfont.woff2") format("woff2"),
    url("../fonts/opensans-semibolditalic-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
