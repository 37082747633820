main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 50px 30px 10px 30px;

  .avatar {
    margin-bottom: 30px;

    img {
      display: block;
      width: 200px;
      border-radius: 100%;
      border: 5px white solid;
    }
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .contact {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;

    li {
      margin: 0 10px;
    }
  }
}
